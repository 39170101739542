import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_sender_dropdown = _resolveComponent("sender-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-account-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_avatar, {
        "placeholder-type": "symbol",
        name: row.account.fullName,
        color: row.account.avatarColor,
        class: "mr-2"
      }, null, 8, ["name", "color"]),
      _createElementVNode("span", null, _toDisplayString(row.account.fullName), 1)
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_sender_dropdown)
    ]),
    _: 1
  }))
}