
import { computed, defineComponent, ref } from 'vue'
import SenderFilter from '@/components/pages/services/countries/sender/SenderFilter.vue'
import SenderTable from '@/components/pages/services/countries/sender/SenderTable.vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders, StatusType } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import SenderEmpty from '@/components/pages/services/countries/sender/SenderEmpty.vue'

type SenderStatus = StatusType<'accepted' | 'rejected' | 'pending'>

type Sender = {
  status: SenderStatus
  senderId: string
  explanation: string
  account: {
    fullName: string
    avatarColor: string
  }
}

export default defineComponent({
  name: 'ServicesCountriesSender',
  components: { SenderEmpty, SenderTable, SenderFilter },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const tableItems: Sender[] = getTableData('servicesSender')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Account', value: 'account-slot' },
      { text: 'Sender ID', value: 'senderId' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Explanation', value: 'explanation' },
    ])
    const filteredTableItems = computed<Sender[]>(() => tableItems.filter((item) => {
      const data = item.account.fullName + item.senderId + item.status.name + item.explanation
      return new RegExp(search.value, 'i').test(data)
    }))

    return {
      isEmptyMode,
      search,
      tableHeaders,
      filteredTableItems,
    }
  },
})
