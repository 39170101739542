
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useCountriesOpenModal from '@/compositions/services/useCountriesOpenModal'

export default defineComponent({
  name: 'SenderFilter',
  components: {
    TmButton,
    TopFilter,
  },
  setup() {
    const { openModalApplySender } = useCountriesOpenModal()

    return {
      openModalApplySender,
    }
  },
})
