
import { defineComponent } from 'vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useCountriesOpenModal from '@/compositions/services/useCountriesOpenModal'

export default defineComponent({
  name: 'SenderEmpty',
  components: { TmButton, TmEmptyState },
  setup() {
    const { openModalApplySender } = useCountriesOpenModal()

    return {
      openModalApplySender,
    }
  },
})
