import { useModals } from '@/compositions/modals'

type UseComposeOpenModal = () => {
  openModalRestoreSenderSettings: () => void
  openModalDeleteSender: () => void
  openModalApplySender: () => void
}

const useCountriesOpenModal: UseComposeOpenModal = () => {
  const { openModal } = useModals()

  const openModalRestoreSenderSettings = () => {
    openModal('confirmation', {
      title: 'Restore default sender settings',
      text: 'Some of your changes might be overwritten when restoring default sender settings.',
      btnText: 'Continue',
    })
  }

  const openModalDeleteSender = () => {
    openModal('confirmation', {
      title: 'Delete Sender ID',
      text: 'Are you sure you would like to delete this Sender ID?',
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openModalApplySender = () => {
    openModal('servicesApplySender')
  }

  return {
    openModalRestoreSenderSettings,
    openModalDeleteSender,
    openModalApplySender,
  }
}

export default useCountriesOpenModal
