
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import SenderDropdown from '@/components/pages/services/countries/sender/SenderDropdown.vue'

export default defineComponent({
  name: 'SenderTable',
  components: { SenderDropdown, TmStatus, TmAvatar, TmTable },
})
