
import { defineComponent } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useCountriesOpenModal from '@/compositions/services/useCountriesOpenModal'

export default defineComponent({
  name: 'SenderDropdown',
  components: { TmDropdownItem, TmDropdown, TmTableActionButton },
  setup() {
    const { openModalDeleteSender } = useCountriesOpenModal()

    return {
      openModalDeleteSender,
    }
  },
})
