import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sender_filter = _resolveComponent("sender-filter")!
  const _component_sender_table = _resolveComponent("sender-table")!
  const _component_sender_empty = _resolveComponent("sender-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_sender_filter, {
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
          }, null, 8, ["search"]),
          _createVNode(_component_sender_table, {
            headers: _ctx.tableHeaders,
            items: _ctx.filteredTableItems,
            "item-key": "id",
            "items-name": "IDs"
          }, null, 8, ["headers", "items"])
        ], 64))
      : (_openBlock(), _createBlock(_component_sender_empty, { key: 1 }))
  ]))
}